<template>
  <div>
    <transition name="fade">
      <v-app v-if="loaded">
        <!-- Sidenav / Navigation drawer -->
        <v-navigation-drawer height="100vh" fixed floating v-model="drawer" :permanent="$vuetify.breakpoint.mdAndUp"
          :temporary="!$vuetify.breakpoint.mdAndUp" class="elevation-1">
          <v-spacer class="mt-16"></v-spacer>
          <v-list
            flat
            class="py-0"
            v-for="item in items"
            :key="item.title">
            <v-list-item
              link
              v-if="item.children.length == 0"
              active-class="menu-active" :color="item.disabled ? 'color lighten-3' : 'primary'"
              class="transparent" :to="item.link"
              @click="pageChanged(0, item)">
              <v-list-item-icon class="mr-3">
                <v-tooltip right>
                  <template v-slot:activator="{on, attrs}">
                    <v-icon size="25" v-on="on" v-bind="attrs">{{ item.icon }}</v-icon>
                  </template>
                  <span style="color:#fff">{{ item.title }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-if="item.children.length > 0"
              v-model="item.active"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
  
              <v-list-item
                v-for="child in item.children"
                :key="child.title"
                :to="child.link"
                class="pl-15">
                <!-- <v-list-item-icon>
                  <v-tooltip right>
                    <template v-slot:activator="{on, attrs}">
                      <v-icon size="25" v-on="on" v-bind="attrs">{{ child.icon }}</v-icon>
                    </template>
                    <span style="color:#fff">{{ child.title }}</span>
                  </v-tooltip>
                </v-list-item-icon> -->
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
  
          </v-list>
          <!-- <v-list flat>
            <template v-for="(item, id) in items">
              <can :key="id" I="create" :a="item.permission">
                <v-list-item active-class="menu-active" :color="item.disabled ? 'color lighten-3' : 'primary'"
                  v-if="item.items == undefined && !item.disabled" class="transparent" :key="item.title" :to="item.link"
                  @click="pageChanged(0, item)">
                  active-class="menu-active"
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
  
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
  
                <v-list-group v-else-if="!item.disabled" :key="id" :prepend-icon="item.icon" active-class="menu-active">
                  <template slot="activator">
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </template>
  
                  <v-list-item class="background" color="primary" v-for="item in item.items" :key="item.title"
                    :to="item.link">
                    <v-list-item-icon>
                      <v-icon v-text="''"></v-icon>
                    </v-list-item-icon>
  
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </can>
            </template>
          </v-list> -->
        </v-navigation-drawer>
  
        <!-- Topnav / App bar -->
        <v-main class="background" :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0 }">
          <v-app-bar color="background" :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0 }" flat fixed>
            <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" @click="drawer = true"></v-app-bar-nav-icon>
  
            <v-toolbar-title>
              <h3 class="font-weight-regular">{{ pageTitle }}</h3>
            </v-toolbar-title>
  
            <v-spacer></v-spacer>
  
            <div>
              <v-menu
                right
                :close-on-content-click="false"
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    :content="total"
                    :value="total"
                    color="primary"
                    overlap
                    class="mx-3"
                  >
                      <v-icon v-bind="attrs"
                      v-on="on" @click="getAll()">$notification</v-icon>
                  </v-badge>
                </template>

                <v-card
                  elevation="6">
                  <v-card-title class="text-subtitle-1 pb-2 mb-0 font-weight-bold">
                  Notifikasi
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-tabs grow background-color="primary" class="notif">
                    <v-tab class="text-capitalize white--text" @click="getAll('')">Semua</v-tab>
                    <v-tab class="text-capitalize white--text" @click="getAll('reads=1')">Dibaca</v-tab>
                    <v-tab class="text-capitalize white--text" @click="getAll('reads=0')">Belum Dibaca</v-tab>
                  </v-tabs>
                  <v-list dense class="" style="width: 360px;max-height: 450px;overflow-y: scroll;">
                    <v-list-item v-if="notif.length ==0">
                      <v-list-item-content>Belum ada notifikasi</v-list-item-content>
                    </v-list-item>

                    <v-list-item v-else v-for="(item, i) in notif" :key="i" style="border-bottom:1px solid #0000001f;" @click="changeStatus(item.id)" :class="{'bgRead': item.read === false}">
                      <v-list-item-content>
                        <div class="d-flex my-1">
                          <v-icon
                            v-if="item.id"
                            class="mr-1 mt-0"
                            color="green darken-3"
                            small
                            >ri-check-</v-icon
                          >
                          <div>
                            
                          <p class="mb-1 body-2 font-weight-bold">{{ item.notification.title}}</p>
                          <div v-if="item.additional != null">
                            <div style="background: #bdc3c730 !important; width: 300px; font-size: 11px; line-height: 2em; " class="px-2 py-1 rounded-lg text-capitalize">
                              <div class="d-flex justify-start " style="gap: 4px">
                                <div class="font-weight-bold">Program:</div>
                                <div class="font-weight-bold" style="color: #636e72">{{ item.additional.program.name }}</div>
                              </div>
                              <div class="d-flex justify-start " style="gap: 4px">
                                <div class="font-weight-bold" style=" ">Topik:</div>
                                <div class="font-weight-bold" style="color: #636e72">{{ item.additional.topic.name }}</div>
                              </div>
                              <div class="d-flex justify-start " style="gap: 4px">
                                <div class="font-weight-bold" style="">Aktifitas:</div>
                                <div class="font-weight-bold" style="color: #636e72">{{ item.additional.activity.name }}</div>
                              </div>
                            </div>
                          </div>
                          <div class="text-muted text-body-2" v-html="item.notification.description"></div>
                          <span small class="text-caption primary--text font-weight-bold">{{ currentDateTime(item.created_at) }}</span>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-card-actions v-if="limitNotif <= totalNotif" class="d-flex justify-center align-center subtitle-2 primary--text" style="cursor: pointer">
                      <div v-if="isLoading" class="text-center my-1">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <div
                        v-if="!isLoading"
                        @click="getNewNotifData()"
                        block
                        small>
                        Tampilkan lebih banyak
                      </div>
                    </v-card-actions>
                  </v-list>
                </v-card>
              </v-menu>

              <!-- PRIVATE MESSAGE -->
              <v-menu
                right
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    :content="totalPrivate"
                    :value="totalPrivate"
                    color="primary"
                    overlap
                    class="mx-3"
                  >
                      <v-icon v-bind="attrs" v-on="on" @click="getAllNotifPrivate()"> 
                        ri-mail-line
                      </v-icon>
                  </v-badge>
                </template>

                <v-card
                  elevation="6"
                >
                  <v-card-title class="text-subtitle-1 pt-2 pb-2 mb-0 font-weight-bold">
                  Pesan Diskusi Private
                  </v-card-title>
                  <v-divider></v-divider>

                  <v-list dense three-line class="" style="width: 360px;max-height: 450px;overflow-y: scroll;">
                    <v-list-item v-if="notifPrivate.length ==0">
                      <v-list-item-content>Belum ada pesan terbaru</v-list-item-content>
                    </v-list-item>

                    <v-list-item v-else v-for="(item, i) in notifPrivate" :key="i" style="border-bottom:1px solid #0000001f;" @click="changeStatusPrivate(item)" :class="{'bgRead': item.read === false}">
                      <v-badge
                        :content="item.unread_message"
                        :value="item.unread_message"
                        class="mr-4"
                        overlap>
                        <v-avatar>
                          <v-img
                            :src="require('@/assets/images/no-pict.png')"
                            :alt="item.member.fullname"
                            ></v-img>
                        </v-avatar>
                      </v-badge>
                      <v-list-item-content>
                        <div class="d-flex">
                            <div>                          
                              <p class="mb-1 font-weight-bold subtitle-2">{{ item.member.fullname}}</p>
                              <div style="background: #bdc3c730 !important; width: 300px; font-size: 11px; line-height: 2em; " class="px-2 py-1 rounded-lg text-capitalize">
                                <div class="d-flex justify-start " style="gap: 4px">
                                  <div class="font-weight-bold">Program:</div>
                                  <div class="font-weight-bold" style="color: #636e72">{{ item.program.title }}</div>
                                </div>
                              </div>
                              <div class="text-muted caption" v-html="item.last_message"></div>
                              <span small class="text-caption color-disable">{{ currentDateTime(item.updated_at) }}</span>
                            </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>

                    <v-card-actions v-if="limitNotifPrivate <= totalNotifPrivate" class="d-flex justify-center align-center subtitle-2 primary--text" style="cursor: pointer">
                      <div v-if="isLoading" class="text-center my-1">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <div
                        v-if="!isLoading"
                        @click="getNewNotifPrivateData()"
                        block
                        small>
                        Tampilkan lebih banyak
                      </div>
                    </v-card-actions>

                  </v-list>
                </v-card>
              </v-menu>

            </div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-6 rounded-pill" v-ripple v-bind="attrs" v-on="on">
                  <v-avatar class="elevation-1 white mr-2" size="40px">
                    <img src="@/assets/images/no-pict.png" alt="Avatar" />
                  </v-avatar>
                  <span class="font-weight-medium">{{ user.fullname }}</span>
                  <v-icon class="mx-2" small>$dropdown</v-icon>
                </div>
              </template>
  
              <v-list dense>
                <!-- <v-list-item link to="/profil">
                  <v-list-item-title v-text="'Profil'"></v-list-item-title>
                </v-list-item>
                <v-list-item link to="/update-password">
                  <v-list-item-title
                    v-text="'Update Password'"
                  ></v-list-item-title>
                </v-list-item> -->
                <v-list-item link @click="logout">
                  <v-list-item-title v-text="'Logout'"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar>

          <notifications 
            group="sw-message" 
            position="bottom right"/>

          <v-snackbar v-model="snackbar.state" :timeout="5000" top center outlined color="primary">
            {{ snackbar.text }}
          </v-snackbar>
  
          <v-container fluid style="margin-top: 64px">
            <div class="content-wrapper mx-2">
              <v-breadcrumbs class="px-0 pt-0" :items="breadcrumbs">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item>
                    <span v-if="item.disabled">
                      {{ item.text }}
                    </span>
                    <router-link class="text-decoration-none" v-else :to="item.href">
                      {{ item.text }}
                    </router-link>
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
  
              <transition name="fade-up">
                <router-view v-if="loaded" @page-changed="pageChanged"></router-view>
              </transition>
            </div>
          </v-container>
        </v-main>
      </v-app>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import menu from './menu';
import firebase from '@/firebase';
import moment from "moment";

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: null,
      total: null,
      totalPrivate: null,
      notifications: [1],
      snackbar: {
        state: false,
        text: "",
      },
      notif:[],
      notifPrivate:[],
      pageTitle: "",
      reads: "",
      limitNotif: 10,
      totalNotif: 0,
      limitNotifPrivate: 10,
      totalNotifPrivate: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    items() { return menu.filter((item) => item.permission) }
  },
  created() {
    this.getAll();
    this.getAllNotifPrivate()

    //create browser permission notification
    firebase.messaging().requestPermission()
      .then(() => {
        console.log('Notification permission granted.');
        this.getFcmToken()
        // TODO(developer): Retrieve an Instance ID token for use with FCM.
        // ...
      })
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });


    this.$root.$on("set-page-title", (/** @type {any} */ pageTitle) => {
      this.pageTitle = pageTitle;
    });
    // this.$root.$on("set-breadcrumbs", (breadcrumbs) => {
    //   console.log(this.breadcrumbs);
    //   console.log(breadcrumbs);
    //   this.breadcrumbs = breadcrumbs;
    // });

    let path = "/" + this.$route.path.split("/")[1];
    let index = this._.findIndex(this.items, { link: path });
    if (index != -1) {
      this.pageTitle = this.items[index].title;
      this.breadcrumbs.push({
        text: this.items[index].title,
        disabled: true,
        href: path,
      });
    }

    // get broadcast channel from firebase messaging or get message from firebase messaging onbackground
    const channel = new BroadcastChannel('sw-messages');
    channel.onmessage = (event) => {
      // console.log("🚀 ~ file: Auth.vue:277 ~ created ~ event:", event.data)

      this.$notify({
        group: 'sw-message',
        title:  event.data.notification.title,
        text: event.data.notification.body,
        duration: 10000,
      });

      this.total = this.total+1;
      this.getAll();
      this.getAllNotifPrivate()
    };


    // SETTING FIREBASE MESSAGING
    firebase.messaging().onMessage((payload) => {
      // console.log("🚀 ~ file: Auth.vue:301 ~ firebase.messaging ~ payload:", payload)

      this.$notify({
        group: 'sw-message',
        title:  payload.data.title,
        text: payload.data.body,
        duration: 10000,
      });

      this.total = this.total+1;
      this.getAll();
      this.getAllNotifPrivate()
    });

  },
  methods: {
    //create for me function for get fcm token from firebase
    async getFcmToken() {
      try {
        const token = await firebase.messaging().getToken({ vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY });
        if (token) {
          // console.log('token', token);
          // this.$store.dispatch('updateFcmToken', token);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
      }
    },

    getNewNotifPrivateData(){
      this.limitNotifPrivate = this.limitNotifPrivate + 10;
      this.getAll();
    },

    getAllNotifPrivate(){
      this.getNotifPrivate();
      this.getNotifPrivateTotal();
    },

    async getNotifPrivate(){
      this.isLoading = true;
      await this.axios
        .get(`discussion/v1/discussion-private/list?limit=${this.limitNotifPrivate}&page=1&sort_by=updated_at&sort_type=desc`)
        .then((response) => {
          let res = response.data.data;
          this.notifPrivate = res.list;
          this.totalNotifPrivate=res.total_data;
          this.isLoading = false;
        });
    },
    getNotifPrivateTotal(){
      this.axios
        .get("discussion/v1/discussion-private/get-total-unread")
        .then((response) => {
          this.totalPrivate= response.data.data.total_unread;
        });
    },

    changeStatusPrivate(item) {
      // console.log(id)
      this.axios
        .put(`discussion/v1/discussion-private/read/${item.id}`)
        .then(() => {
          this.getAllNotifPrivate();

          this.$router.push(`/discussion/private/program/${item.program.id}`);
          setTimeout(() => {
            window.location.reload();
          }, 10);
        });
    },

    getNewNotifData(){
      this.limitNotif = this.limitNotif + 5;
      this.getAll(this.reads);
    },
    getAll(reads){
      this.reads = reads;
      this.getNotif();
      this.getNotifUnread();
    },

    getNotif(){
      this.isLoading = true;
      this.axios
        .get(`notification/v1/trainer/list?limit=${this.limitNotif}&page=1&sort_by=_id&dir=desc&${this.reads ? this.reads : ''}`)
        .then((response) => {
          let res = response.data.data;
            this.notif = res.list;
            this.totalNotif=res.total_data;
            this.isLoading = false;
        });
    },
    getNotifUnread(){
      this.axios
        .get("notification/v1/trainer/list?limit=-1&page=1&sort_by=_id&dir=desc&reads=0")
        .then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.total=res.data.total_data;
          }
        });
    },

    changeStatus(id) {
      // console.log(id)
      this.axios
        .put(`notification/v1/trainer/read/${id}`)
        .then((response) => {
          let res = response.data
          if(res.status == 200) {
            this.snackbar.text = `Notifikasi sudah terbaca`;
            this.snackbar.state = true;   
            this.getAll();
          }
        });
    },

    currentDateTime(a) {
      return moment.utc(a).locale('ID').fromNow();
    },

    async logout() {
      // make axios request to logout with this url /v1/logout 
      // and then redirect to login page
      await firebase.messaging()
      .deleteToken({ token: localStorage.getItem("fcm_token") })
      .then(() => {
          this.axios.post("/trainer/v1/logout").then(() => {
            this.$store.dispatch("logout").then(() => {
              localStorage.removeItem("fcm_token");
              console.log("Token deleted successfully");
              this.$router.push("/");
            });
          });
      }).catch((err) => {
        this.$router.push("/");
        console.log("Unable to delete token. ", err);
      });
    },
    /**
    * @param {{ title: any; }} item
    */
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    /**
    * @param {any} segment
    * @param {any} location
    */
    pageChanged(segment, location = null) {
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((/** @type {{ disabled: boolean; }} */ item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }

      document.title =
        this.breadcrumbs
          .slice()
          .reverse()
          .map((/** @type {{ text: any; }} */ val) => val.text)
          .join(" / ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>
<style>
.notif .v-tabs-slider {
  background-color: white;
  height: 100%;
  width: 100%;
}
.bgRead{
  background-color: rgba(240, 83, 38, 0.1);
}
</style>
