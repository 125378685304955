<template>
  <div>
    <div v-if="isLoading" class="white d-flex flex-column justify-center align-center mx-auto"
      style="height: 100vh; width: 100vw;">
      <v-progress-circular :size="100" color="primary" indeterminate>
      </v-progress-circular>
      <div class="mt-10">Menyiapkan aplikasi untuk Anda, silahkan perbolehkan notifikasi ....</div>
    </div>
    <v-row v-else align-content="center" class="white">
      <v-col class="py-0 mx-auto" md="7">
        <v-row>
          <v-col class="white px-12" md="11" style="height: 100vh">
            <v-spacer class="py-16"></v-spacer>
            <v-form ref="form" class="px-8" @submit.prevent="submit" v-model="valid">
              <div class="mx-auto text-center mb-2">
                <img :src="header_logo" class="me-2" style="max-width: 150px;">
                <!-- <img src="@/assets/images/logo-prakerja.png" class=""> -->
              </div>
              <v-row>
                <v-col>
                  <div class="subtitle-2 mb-2">Email</div>
                  <v-text-field filled v-model="email" placeholder="Masukkan email"
                    :rules="[...rules('Email', 'required'), validate]" required outlined
                    hide-details="auto"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <div class="mb-2">
                    <span class="subtitle-2">Password</span>
                  </div>
                  <v-text-field filled v-model="password" placeholder="Masukkan password"
                    :type="show ? 'text' : 'password'" :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                    @click:append="show = !show" outlined hide-details="auto"
                    :rules="rules('Password', 'required')"></v-text-field>
                </v-col>
              </v-row>
              <v-spacer class="py-2"></v-spacer>
              <v-row>
                <v-col>
                  <v-btn large elevation="0" type="submit" color="primary" :loading="submiting" block>
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AbilityBuilder } from "@casl/ability";
import validationMixin from "../_mixins/validation";
import firebase from '@/firebase';

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      valid: true,
      isLoading: false,
      errors: null,
      email: "",
      password: "",
      token: "",
      submiting: false,
      show: false
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
    ...mapState({
      site_data: state => state.company.site_data
    }),
    header_logo() {
      return this.site_data.logo?.url;
    },
  },
  mounted() {
    this.getFcmToken();
  },
  methods: {
    async getFcmToken() {
      this.isLoading = true;
      if (firebase.messaging.isSupported()) {
        if (!this.token) {
          // firebase messaging request permission
          firebase.messaging().requestPermission()
            .then(async () => {
              console.log('Notification permission granted.');

              // get user agent for detect browser if safari then set isSafari to true
              let userAgent = navigator.userAgent || navigator.vendor || window.opera;
              if (/iPad|iPhone|iPod/.test(userAgent) || !window.MSStream || navigator.userAgent.indexOf("Safari") > -1) {
                this.isLoading = false;
              }

              try {
                const token = await firebase.messaging().getToken();
                // console.log("🚀 ~ file: login.vue:203 ~ .then ~ token:", token)
                if (token) {
                  this.token = token;
                  localStorage.setItem('fcm_token', token);
                  this.isLoading = false;
                } else {
                  this.isLoading = false;
                  console.log('No registration token available. Request permission to generate one.');
                }
              } catch (error) {
                this.isLoading = false;
                console.log('An error occurred while retrieving token. ', error);
              }
            })
            .catch((err) => {
              this.isLoading = false;
              console.log('Unable to get permission to notify.', err);
            });
        }
      } else {
        if (!this.token) {
          console.log('Firebase messaging is not supported.');

          navigator.permissions.query({ name: 'notifications' })
            .then((permission) => {
              if (permission.state === 'granted') {
                this.isLoading = true;
                // If push notification is allowed then get token from firebase messaging
                try {
                  firebase.messaging().getToken()
                    .then((token) => {
                      if (token) {
                        this.token = token;
                        localStorage.setItem('fcm_token', token);
                        this.isLoading = false;
                      } else {
                        this.isLoading = false;
                        console.log('No registration token available. Request permission to generate one.');
                      }
                    })
                } catch (error) {
                  this.isLoading = false;
                  console.log('An error occurred while retrieving token. ', error);
                }


              } else if (permission.state === 'denied') {
                this.isLoading = false;
                console.log('User has blocked notifications.');
              } else if (permission.state === 'prompt') {
                this.isLoading = false;
                console.log('User has not yet been asked for notification permission');
              }
            }).catch((err) => {
              this.isLoading = false;
              console.log('An error occurred while retrieving token. ', err);
            })

        }
      }
    },
    updateAbility(user) {
      const { can, rules } = new AbilityBuilder();

      if (user.role === 'superadmin') {
        can('manage', 'all');
      } else {
        can('create', 'Program');
      }

      this.$ability.update(rules);
    },
    submit() {
      this.errors = null;

      setTimeout(async () => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          let email = this.email;
          let password = this.password;
          let token = this.token;

          this.$store
            .dispatch("login", { email, password, token })
            .then((user) => {

              this.submiting = false;

              this.updateAbility({
                role: user?.role?.key || "superadmin"
              });

              // if (sessionStorage.getItem("ytc_redirect") != null) {
              //   this.$router.push(sessionStorage.getItem("ytc_redirect"));
              //   sessionStorage.removeItem("ytc_redirect");
              // } else {
              // }
              this.$router.push("/dashboard");
            })
            .catch((error) => {
              this.submiting = false;
              this.errors = error.response.data;
            });

        }
      }, 50);
    },
  },
};
</script>

<style></style>