<template>
  <v-row>
    <template>
      <v-col cols="12">
        <div class="mb-2">Nama Fasilitator</div>
          <v-text-field filled hide-details="auto" v-model="data.name" placeholder="Masukan nama fasilitator" class=""></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-2">Deskripsi</div>
                    <!-- <vue-editor v-model="fasilitator.description" :editor-toolbar="customToolbar" placeholder="Masukan deskripsi"/> -->
                    <!-- <ckeditor :editor="editor" v-model="fasilitator.etc" :config="editorConfig"></ckeditor>              -->
                    <editor
                      api-key="4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq"
                      v-model="data.description"
                      placeholder="Masukan deskripsi"
                      :init="{
                         height: 350,
                         menubar: false,
                         plugins: [
                           'advlist autolink lists link charmap print preview anchor',
                           'searchreplace visualblocks code fullscreen',
                           'insertdatetime media table paste code help wordcount'
                         ],
                         toolbar:
                           'undo redo | formatselect | bold italic backcolor | \
                           alignleft aligncenter alignright alignjustify | \
                           bullist numlist | link table',                           
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
                        }"
                    />
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-2">Foto</div>
                    <div class="helper"></div>
                    <div class="drop display-inline text-center" @dragover.prevent @drop="onDrop2">
                        <div v-if="!data.picture" class="content-img pt-12">                    
                          <p class="mb-2 pt-8"><v-icon medium>ri-image-add-line</v-icon></p>
                          <p class="mb-0 subtitle-2">Tambahkan Gambar</p>
                          <p class="mb-2 subtitle-2">dengan</p>
                          <label class="btn-img display-inline text-center">
                            Pilih Berkas
                            <input type="file" name="image2" @change="onChange2" accept="image/*">
                          </label>
                        </div>                  
                        <div class="hidden display-inline align-center py-4" v-else v-bind:class="{ 'image2': true }">
                          <div class="box-img-line " style="width: 100%;margin:auto;position: relative;">                      
                            <img :src="this.url || this.urlUp" alt="" class="img-cover" />                    
                            <div class="btn-float">                        
                              <label class="fab-white bg-label mx-2">
                                <v-icon dark>
                                  ri-arrow-go-back-line
                                </v-icon>
                                <input type="file" name="image" @change="onChange2" accept="image/*">
                              </label>
                              <!-- <v-btn small class="mx-2 fab-white" fab @click="removeFile2">
                                <v-icon dark>
                                  ri-delete-bin-7-line
                                </v-icon>
                              </v-btn> -->
                            </div>
                          </div>
                        </div>
                    </div>
                  </v-col>
    <v-snackbar 
      top 
      v-model="snackbar.state"
      color="primary"
      outlined
      :timeout="timeout"
    >
      <!-- :timeout="timeout" -->
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> 
    </template>
  </v-row>
</template>

<script>
// import { VueEditor } from "vue2-editor";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
 import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    // VueEditor,
    'editor': Editor
  },
  props: {
    data: Object,
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      error: "",
      snackbar: {
        state: false,
        text: "",
      },
      timeout:5000,
      
      // editor: ClassicEditor,
      //           editorData: '<p>Content of the editor.</p>',
      //           editorConfig: {
      //               // The configuration of the editor.
      //           },
      fasilitator:{},
      url:this.data.picture.url,
      urlUp:'',
      //   name:'',
      //   description:'',
      //   picture:{
      //     id:''
      //   }
      // },
      image2:'',
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block","image"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"] // remove formatting button
      ],
    };
  },
  created() {
    this.fetchFcl();
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        this.loading ||
        (this.data.status && this.data.status != "draft")
      );
    },
    // name() {
    //   return this.data.name || null;
    // },
    // description(){
    //   return this.data.description || null;
    // },
    // picture(){
    //   return this.data.picture || undefined;
    // }
  },
  methods: {
    fetchFcl(){
      if (this.data.picture.id) {
        this.url=this.data.picture.url;
        let param={
          name:this.data.name,
          description:this.data.description,
          picture:this.data.picture.id
        }
        this.fasilitator=param;
        this.$emit("update", this.fasilitator);
      }else{
         Object.assign(this.fasilitator, this.data);
      }
    },
    onDrop2: function(e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;
        this.createFile(files[0]);
    },
    onChange2(e) {
        var files = e.target.files;
        this.createFile2(files[0]);
        this.uploadCover2(files[0]);  
    },
    createFile2(file) {
        if (!file.type.match('image.*')) {
          alert('Select an image');
          return;
        }
        // var img = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = function(e) {
          vm.image2 = e.target.result;
        }
        reader.readAsDataURL(file);
    },
    uploadCover2(file){
      var formData = new FormData();
      // let a = file;
      // console.log('img',a);

      formData.append("file", file, "fasilitator.jpg");
      formData.append("source", "upload");
      formData.append("title", "fasilitator");

      // console.log('result', formData);
      this.snackbar.text = 'Loading Upload ...';
      this.snackbar.state = true;
      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          if (res.status == 200) {
            this.data.picture = res.data.id;
            this.url=res.data.public_url;
            // console.log('result', this.id_cover)
            this.snackbar.text = 'Sukses Upload Fasilitator';
            this.snackbar.state = true;
          }
        })
        .catch(() => {
          // console.log("error", error);
        });
    },
    removeFile2() {
      this.image2 = '';
    },
  },
  watch: {
    "data.name": function () {
      this.fetchFcl();
      // handler: function () {
      // },
      // deep: true,
    },
    fasilitator: {
      handler: function (fasilitator) {
        if (!this.isDisabled) {
          this.$emit("update", fasilitator);
        }
      },
      deep: true,
    },
  },
};
</script>