import axios from "axios";
import url from "./api";

const config = { headers: { "content-type": "application/json" } };

const state = () => ({
  data: {},
  params: {},
  dataPublish:{},
  paramsPublish: {},
  dataDraft:{},
  dataArchive:{}
});

const getters = {
  data: (state) => state.data,
  params: (state) => state.params,
  list: (state) => state.data.list || [],
  limit: (state) => state.data.limit || 10,
  page: (state) => state.data.page || 1,
  total: (state) => state.data.total || "0",
  detail: (state) => state.data.detail || {},
};

const mutations = {
  SET_DATA: (state, data) => {
    state.data = { ...state.data, ...data };
  },
  SET_PARAMS(state, data) {
    state.params = data;
  },
  SET_DETAIL: (state, data) => {
    state.data = { ...state.data, ...{ detail: data } };
  },
};

const actions = {
  // async list({ commit, getters }, params) {
  async list({ state, commit }, params ) {
    let a ={
        page: state.data.page, limit: state.data.limit, sort: 'total_unread', sort_type: params.sort_type
      }
      params = {
        ...a,
        ...state.params,
        ...params,
      };
      // console.log('params umum', params)
      state.data.list = [];
      return axios.get(url.program_discussion_list,{ params })
        .then((res) => res.data)
        .then((res) => {
          delete params.page;
          delete params.limit;
          commit("SET_PARAMS", params);
          commit("SET_DATA", res.data);
          return res.data;
        });

    // params = { ...{ limit: getters.limit, page: getters.page, sort: 'created_at', sort_type: 'desc' }, ...params };
    // return axios
    //   .get(url.program_list, { params })
    //   .then((res) => res.data)
    //   .then((res) => {
    //     commit("SET_DATA", res.data);
    //   });
  },
  async detail({ commit }, id) {
    return axios
      .get(url.program_detail.replace("{id}", id))
      .then((res) => res.data)
      .then((res) => {
        commit("SET_DETAIL", res.data);
      });
  },
  async create({ dispatch }, data) {
    return axios.post(url.program_create, data).then(dispatch("list"));
  },
  async update({ dispatch }, data) {
    data = {
      id: data.id,
      category_id: data.category_id || data.category.id,
      name: data.name,
      available_part: data.available_part,
    };

    return axios
      .post(url.program_update.replace("{id}", data.id), data, config)
      .then(dispatch("list"));
  },
  async delete({ dispatch }, id) {
    return axios
      .delete(url.program_delete.replace("{id}", id))
      .then(dispatch("list"));
  },
};

export default { namespaced: true, state, getters, mutations, actions };
