// import { roomService } from "../_services/room.service";
import axios from "axios";
const state = { activeRoom: null, trainerID: null, users: [] };

const actions = {
  async selectRoom({ commit }, { room, program_id, trainer_id }) {

    // If the room has already been created, then set it as active room (usually triggered from selecting the chat room from left)
    var roomID;
    if (room) {
      roomID = room;
    } else {
      // Else check if there is existing chat room created for the user
      const roomResp = await axios.post(`/discussion/v1/discussion-private/get-room-id`, {program_id, trainer_id});
      const resroomResp = roomResp.data

      // console.log('roomResp',roomResp);

      if (resroomResp.status == 200) {
        roomID = resroomResp.data.room_id;
      }else {
        const resp = await axios.post(`/discussion/v1/discussion-private/get-room-id`, {program_id, trainer_id});
        const resResp = roomResp.data
        if (resResp.status) {
          roomID = resp.roomID;
        }
      }
    }

    // Get the room detail
    const response = await axios.get(`/discussion/v1/discussion-private/detail-room/${roomID}`);
    const res = response.data
    if (res.status == 200) {
        // && rootState.contactModule.users
        // Postprocessing on the users
        // var users = rootState.trainerModule.trainers.filter(function (val) {
        //   return res.data.trainer_id.indexOf(val.id) >= 0;
        // });

      // commit("setUsers", users);
    }

    commit("setActiveRoom", roomID);
    commit("setTrainerId", trainer_id);
  },
  async sendMessage({ state, rootState }, { message, reference_id }) {
    // var sender = 
    rootState.userModule.user
      ? rootState.userModule.user.ID
      : null;
    var room = state.activeRoom;

    const resp = await axios.post(`/discussion/v1/discussion-private/room/${room}/send-message`, { message, reference_id })
    // roomService.sendMessage(sender, room, message);
    const resResp = resp.data

    // console.log('resp send',resp);
    return resResp.data;
  },
  async createChatRoom({ program_id, trainer_id }) {
    // var currentUserID = rootState.userModule.user
    //   ? rootState.userModule.user.ID
    //   : null;
    // console.log(rootState);
    // if (currentUserID) {
    //   userIDs.push(currentUserID);
    // }

    const resp = await axios.post(`/discussion/v1/discussion-private/get-room-id`, {program_id, trainer_id});
    // console.log('resp create',resp);
    return resp;
  },
  clearRoom({ commit }) {
    commit("clearRoom");
  },
};

const mutations = {
  setTrainerId(state, trainer_id) {
    state.trainerID = trainer_id;
  },
  setActiveRoom(state, room) {
    state.activeRoom = room;
  },
  clearRoom(state) {
    state.activeRoom = null;
    state.users = [];
  },
  setUsers(state, users) {
    state.users = users;
  },
};

export const roomModule = {
  namespaced: true,
  state,
  actions,
  mutations,
};
