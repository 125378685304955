import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/firebase-messaging'

const firebaseConfig = {
  apiKey: "AIzaSyAIwoR7TkQgZccXfk5QaY29-3wg4ensTvs",
  authDomain: "lms-teflin.firebaseapp.com",
  projectId: "lms-teflin",
  storageBucket: "lms-teflin.appspot.com",
  messagingSenderId: "38691587708",
  appId: "1:38691587708:web:d88ab214ca5f3c2e092bf9",
  measurementId: "G-D5D6P0SRFW"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}


let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
  messaging.getToken({vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY})
}



export default firebase