require("./lib");

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./lib/vuetify";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { abilitiesPlugin, Can } from "@casl/vue";
import appAbility from "./services/ability";
import { AbilityBuilder } from "@casl/ability";
import firebaseMessaging from './firebase'
import Notifications from 'vue-notification'
/*
or for SSR:
import Notifications from 'vue-notification/dist/ssr.js'
*/
 
Vue.use(Notifications)

Vue.use(CKEditor);
Vue.component("can", Can);
Vue.use(abilitiesPlugin, appAbility);
Vue.config.productionTip = false;

if (firebaseMessaging.messaging.isSupported()) {
    const messaging = firebaseMessaging.messaging();

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      // console.log("registrations", registrations);
      if (registrations.length == 0) {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then(async (registration) => {
            await messaging.useServiceWorker(registration);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(function(registration) {
        console.log('Registration successful, scope is:', registration.scope);
      }).catch(function(err) {
        console.log('Service worker registration failed, error:', err);
      });
}

function updateAbility(user) {
    const { can, rules } = new AbilityBuilder();

    if (user.role === "superadmin") {
        can("manage", "all");
    } else {
        can("create", "Program");
    }

    appAbility.update(rules);
}
(async () => {
    const user = await store.dispatch("get_user");
    await store.dispatch("getSiteData");

    updateAbility({
        role: user?.role?.key || "superadmin"
    });

    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount("#app");
})();
